import { get, patch } from '../helpers'

export default {
  getEmailSettings() {
    const path = 'api/email_settings'
    return get(path)
  },

  updateEmailSettings(data) {
    const path = 'api/email_settings'
    return patch(path, { data })
  }
}
