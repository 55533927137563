import { isValidValue } from '../helpers/values'

const getValue = (key, stat) => {
  return stat && stat[key] ? stat[key] : null
}

const getSalesValue = stat => {
  const validKey = stat?.estimatedSales !== undefined || null
  return stat && validKey ? stat.estimatedSales : null
}

const getNestedValue = (key, stat) => {
  return stat && stat[key] ? stat[key].value : null
}

const getInventoryValue = stat => {
  const tInv = getNestedValue('inventory', stat)
  let tAltInv = getValue('altInventory', stat)
  tAltInv = tAltInv > 999 ? 999 : tAltInv

  return tInv || tAltInv
}

const calcSalesThreshold = estSales => {
  return !estSales || estSales < 1 ? 10 : 2.5 * estSales
}

const isReservedInventory = (tInvVal, yInvVal, estSales) => {
  if (!yInvVal || !tInvVal) {
    return false
  }

  const salesThreshold = calcSalesThreshold(estSales)
  const realSales = yInvVal - tInvVal

  return realSales > salesThreshold
}

const isReservedValue = inventory => {
  return !!inventory[1] && inventory[0] === 'res'
}

const isLimitedValue = inventory => {
  return !!inventory[1] && inventory[0] === 'lim'
}

const shouldReserveInventory = (
  tInvVal,
  yInvVal,
  tSeller,
  ySeller,
  estSales
) => {
  return (
    isReservedInventory(tInvVal, yInvVal, estSales) &&
    !isSellerChange(tSeller, ySeller)
  )
}

const isSellerChange = (todaySeller, yesterdaySeller) => {
  const tSeller = todaySeller ? todaySeller.trim() : todaySeller
  const ySeller = yesterdaySeller ? yesterdaySeller.trim() : yesterdaySeller

  return (
    tSeller !== ySeller &&
    (!!tSeller && !!ySeller && tSeller !== 'N.A.' && ySeller !== 'N.A.')
  )
}

const isMeasurableInventoryChange = (tInvVal, yInvVal) => {
  return (
    isValidValue(tInvVal) &&
    isValidValue(yInvVal) &&
    tInvVal <= yInvVal &&
    tInvVal < 999 &&
    yInvVal < 999
  )
}

const getEstimatedSales = stat => {
  let sales = getValue('estimatedSales', stat)
  sales = isValidValue(sales) ? Math.round(sales) : null
  return ['est', sales]
}

const getRealSales = stat => {
  let sales = getValue('sales', stat)
  sales = isValidValue(sales) ? Math.round(sales) : null
  return ['ok', sales]
}

const getEstimatedAsRealSales = stat => {
  // below function required to also get 0 values
  let sales = getSalesValue(stat)
  sales = isValidValue(sales) ? Math.round(sales) : null
  return ['ok', sales]
}

const getInventory = (stat, yInv, limitedInv, ySeller) => {
  const tInvVal = getInventoryValue(stat)
  const yInvVal = yInv ? yInv[1] : null

  const tSeller = getValue('sellerName', stat)

  const estSales = getValue('estimatedSales', stat)

  if (stat && stat.isLimited) {
    return ['lim', tInvVal]
  }
  if (stat && !!limitedInv) {
    return ['lim', limitedInv]
  }
  if (shouldReserveInventory(tInvVal, yInvVal, tSeller, ySeller, estSales)) {
    return ['res', tInvVal]
  }
  if (!isValidValue(tInvVal)) {
    return ['none', null]
  }
  return ['ok', tInvVal]
}

const getUnitsSold = (stat, tInv, yInv, ySeller) => {
  const tSeller = getValue('sellerName', stat)
  const tSales = getValue('sales', stat)
  const tInvVal = tInv ? tInv[1] : null
  const yInvVal = yInv ? yInv[1] : null

  if (
    isSellerChange(tSeller, ySeller) ||
    isReservedValue(tInv) ||
    isLimitedValue(tInv) ||
    !isMeasurableInventoryChange(tInvVal, yInvVal) ||
    !tSales
  ) {
    return getEstimatedSales(stat)
  }
  return getRealSales(stat)
}

const checkSellerChange = (ySeller, seller) => {
  // if ySeller or seller is null need to ignore
  if (!ySeller || !seller || ySeller === seller) {
    return false
  }

  return true
}

export default {
  getInventory,
  getUnitsSold,
  getEstimatedAsRealSales,
  checkSellerChange,
  // needed for tests
  shouldReserveInventory
}
