import find from 'lodash/find'
import uniq from 'lodash/uniq'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import round from 'lodash/round'

import { addDaysToEpoch, epochToUtcDate } from 'helpers/date'
import ChartCalculator from './chart_calculator'

function getValue(key, stat) {
  return stat && stat[key] ? stat[key] : null
}

function getNestedValue(key, stat) {
  return stat && stat[key] ? stat[key].value : null
}

/**
 * Returns array of dates from X day to today (in unix timestamps)
 */

function getDates(start, end) {
  const dates = []
  let current = start

  while (current <= end) {
    dates.push(current)
    current = addDaysToEpoch(current, 1)
  }

  return dates
}

/**
 * Returns stat on a particular date
 */

function getStatOnDate(stats, date) {
  return find(stats, stat => epochToUtcDate(stat.day) === epochToUtcDate(date))
}

function isMissingAllData(rank, price, inventory, unitsSold) {
  return !rank && !price && !inventory && !unitsSold
}

function getUniqueInventoryValues(stats) {
  const inventoryValues = compact(
    stats.map(stat => getNestedValue('inventory', stat))
  )
  return uniq(inventoryValues)
}

function includesLimitedStat(stats) {
  return !!stats.find(stat => stat.isLimited)
}

function getLimitedInventory(stats) {
  const uniqInventoryValues = getUniqueInventoryValues(stats)
  if (uniqInventoryValues.length === 1 && includesLimitedStat(stats)) {
    return uniqInventoryValues[0]
  }
  return null
}

export function run(startDate, stats) {
  if (!stats || stats.length === 0) return []

  const t0 = startDate
  const t1 = new Date()

  const dates = getDates(t0, t1)

  const datesArray = []
  const rankArray = []
  const priceArray = []
  const inventoryArray = []
  const unitsSoldArray = []
  const sellersArray = []
  const limitedInvArray = []
  const sellerChangeArray = []

  const limitedInventory = getLimitedInventory(stats)

  dates.forEach(date => {
    const stat = getStatOnDate(stats, date)

    const yInv = !isEmpty(inventoryArray)
      ? inventoryArray[inventoryArray.length - 1]
      : null
    const ySeller = !isEmpty(sellerChangeArray)
      ? sellersArray[sellersArray.length - 1]
      : null

    const formattedDate = epochToUtcDate(date)
    let rank = getNestedValue('rank', stat)
    rank = rank ? round(rank) : rank
    const price = getNestedValue('price', stat)

    const inventory = ChartCalculator.getInventory(
      stat,
      yInv,
      limitedInventory,
      ySeller
    )
    const unitsSold = ChartCalculator.getEstimatedAsRealSales(stat)

    let seller = getValue('sellerName', stat)
    seller = seller ? seller.trim() : seller

    const limitedInv = getValue('isLimited', stat)
    const sellerChange = ChartCalculator.checkSellerChange(ySeller, seller)

    // if it's today and hasn't been scraped yet, don't show the stat
    if (
      date === t1.getTime() &&
      isMissingAllData(rank, price, inventory, unitsSold)
    ) {
      return
    }

    datesArray.push(formattedDate)
    rankArray.push(rank)
    priceArray.push(price)
    inventoryArray.push(inventory)
    unitsSoldArray.push(unitsSold)
    sellersArray.push(seller)
    limitedInvArray.push(limitedInv)
    sellerChangeArray.push(sellerChange)
  })

  const dataArrays = {
    datesArray,
    rankArray,
    priceArray,
    inventoryArray,
    unitsSoldArray,
    sellersArray,
    sellerChangeArray,
    limitedInvArray
  }

  return dataArrays
}
