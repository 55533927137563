import { get, post, destroy } from './helpers'

export default {
  getProductAlerts() {
    const path = 'api/product_alerts'
    return get(path)
  },

  addProductAlert(data) {
    const path = 'api/product_alerts'
    return post(path, { data })
  },

  addProductAlertsBulk(data) {
    const path = 'api/product_alerts/bulk'
    return post(path, { data })
  },

  removeProductAlert(id) {
    return destroy(`api/product_alerts/${id}`)
  },

  removeProductAlertsBulk(data) {
    const path = 'api/product_alerts/bulk'
    return destroy(path, { data })
  }
}
