import { get, patch } from '../helpers'

export default {
  getAlertSettings() {
    const path = 'api/alert_settings'
    return get(path)
  },

  updateAlertSettings(data) {
    const path = 'api/alert_settings'
    return patch(path, { data })
  }
}
