import { get, patch } from './helpers'

export default {
  loadProducts({
    search,
    page,
    perPage,
    amazon_seller_account_id,
    marketplace_id,
    orderBy,
    orderDir,
    enabled
  }) {
    let requestUrl = `api/junglekit/products?amazon_seller_account_id=${amazon_seller_account_id}&marketplace_id=${marketplace_id}`
    requestUrl += search ? `&search=${search}` : ''
    requestUrl += page ? `&page=${page}` : ''
    requestUrl += perPage ? `&per_page=${perPage}` : ''
    requestUrl += orderBy ? `&order_by=${orderBy}` : ''
    requestUrl += orderDir ? `&order_dir=${orderDir}` : ''
    requestUrl += typeof enabled === 'boolean' ? `&enabled=${enabled}` : ''

    return get(requestUrl)
  },

  loadChildProducts({
    search,
    perPage,
    amazon_seller_account_id,
    marketplace_id
  }) {
    let requestUrl = `api/junglekit/products/child_products?amazon_seller_account_id=${amazon_seller_account_id}&marketplace_id=${marketplace_id}`
    requestUrl += search ? `&search=${search}` : ''
    requestUrl += perPage ? `&per_page=${perPage}` : ''

    return get(requestUrl)
  },

  getProductsResyncStatus(data) {
    return patch('api/junglekit/product/sync_status', { data })
  }
}
