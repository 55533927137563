import qs from 'qs'

import { get, put, post, destroy } from './helpers'

const BASE_URL = 'api/junglekit/product_costs'

export default {
  getProductsWithCosts(params) {
    const requestUrl = `api/junglekit/products?include[]=product_costs&${qs.stringify(
      params
    )}`

    return get(requestUrl)
  },

  getSingleProductCosts(params) {
    const requestUrl = `api/junglekit/product_costs?${qs.stringify(params)}`

    return get(requestUrl)
  },

  updateProductCost(data) {
    const { id, amazon_seller_account_id } = data
    const path = `${BASE_URL}/${id}?amazon_seller_account_id=${amazon_seller_account_id}`

    return put(path, { data })
  },

  createProductCost(data) {
    return post(BASE_URL, { data })
  },

  deleteProductCost(payload) {
    const { id, amazon_seller_account_id } = payload
    const path = `${BASE_URL}/${id}?amazon_seller_account_id=${amazon_seller_account_id}`

    return destroy(path)
  },

  getProductCostsExport(params) {
    // skipNulls - treat 'null' same as 'undefined' (omit from query string)
    return get(
      `${BASE_URL}/export?${qs.stringify(params, { skipNulls: true })}`
    )
  },

  uploadCostsCSVFile(params, file) {
    return post(`${BASE_URL}/import?${qs.stringify(params)}`, {
      data: { file }
    })
  },

  getProductCostsImportJobStatus(jobId) {
    return get(`api/junglekit/product_costs_import_status?job_id=${jobId}`)
  }
}
