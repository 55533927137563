import { patch, put } from './helpers'

export default {
  updateMarketplace(id, data) {
    const path = `api/junglekit/marketplaces/${id}`
    return put(path, { data })
  },
  updateAllMarketplaces(data) {
    const path = `api/junglekit/marketplaces/bulk`
    return patch(path, { data })
  }
}
