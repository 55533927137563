import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'

const qs = require('qs')

export const pullValidValues = filter => {
  return pickBy(filter, v => {
    return !isInvalid(v)
  })
}

const isInvalid = value => {
  return !value || (isEmpty(value) && typeof value !== 'number')
}

export const buildFilter = filters => {
  const data = {}

  if (!filters) {
    return {}
  }

  Object.entries(filters).forEach(([key, filter]) => {
    if (typeof filter === 'string') {
      data[key] = filter
      return
    }

    if (
      (filter.type === 'query' ||
        filter.type === 'eQuery' ||
        filter.type === 'includeOverrideQuery' ||
        filter.type === 'excludeOverrideQuery' ||
        filter.type === 'shingles') &&
      isInvalid(filter.searchTerm)
    ) {
      // do nothing
    } else if (
      (filter.type === 'terms' ||
        filter.type === 'termsExclude' ||
        key === 'tier') &&
      isInvalid(filter.valuesArray)
    ) {
      // do nothing
    } else if (
      filter.type === 'range' &&
      isInvalid(filter.min) &&
      isInvalid(filter.max)
    ) {
      // do nothing
    } else if (
      filter.type === 'paginate' &&
      isInvalid(filter.pageSize) &&
      isInvalid(filter.from)
    ) {
      // do nothing
    } else if (filter.type === 'sort' && isInvalid(filter.column)) {
      // do nothing
    } else if (key === 'query' && filter.searchTerm === '*') {
      // do nothing
    } else {
      data[key] = pullValidValues(filter)
    }
  })

  return data
}

export const buildFilterQuery = (
  filters,
  skipCounter = false,
  // TODO: Consider moving 'excludeTopBrands' to a more concrete function because
  // this seems relevant only to some HTTP requests, and this file is considered abstract.
  excludeTopBrands = false
) => {
  const data = buildFilter(filters)

  return qs.stringify(
    { data, skipCounter, excludeTopBrands },
    { arrayFormat: 'brackets' }
  )
}

const mapArrayToSerial = (array, keyName) => {
  // EG: ['flavours[]=vanilla', 'flavours[]=chocolate', 'flavours[]=scorpion']
  return array.map(
    item => `${encodeURIComponent(keyName)}[]=${encodeURIComponent(item)}`
  )
}

const serialize = (obj, prefix) => {
  let str = []
  Object.keys(obj).forEach(p => {
    const k = prefix ? `${prefix}[${p}]` : p
    const v = obj[p]

    if (v !== null && v !== undefined && v.constructor === Array) {
      const arraySerialized = mapArrayToSerial(v, p)
      str = [...str, ...arraySerialized]
    } else if (v !== null && typeof v === 'object') {
      str.push(serialize(v, k))
    } else {
      str.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    }
  })
  return str.join('&')
}

export const buildFullPath = (path, data, format) => {
  const url = `${path}${format ? `.${format}` : ''}`

  return `${url}?${serialize(data, undefined)}`
}

/*
  Serializes params without including null or undefined values
*/
const serializeClean = (obj, prefix) => {
  let str = []
  Object.keys(obj).forEach(p => {
    const k = prefix ? `${prefix}[${p}]` : p
    const v = obj[p]

    if (v != null) {
      if (v.constructor === Array) {
        const arraySerialized = mapArrayToSerial(v, p)
        str = [...str, ...arraySerialized]
      } else if (typeof v === 'object') {
        str.push(serialize(v, k))
      } else {
        str.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
      }
    }
  })
  return str.join('&')
}

/*
  Builds path without parameters that are null or undefined
*/
export const buildFullPathClean = (path, data, format) => {
  const url = `${path}${format ? `.${format}` : ''}`

  return `${url}?${serializeClean(data, undefined)}`
}
