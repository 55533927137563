import { post, destroy } from './helpers'

export default {
  loginUser(data) {
    const path = `users/sign_in`
    return post(path, { data })
  },

  logoutUser(data) {
    const path = `users/sign_out`
    return destroy(path, { data })
  }
}
