import { post } from './helpers'

const BASE_URL = 'api/junglekit/ppc_credentials'

export default {
  createPpcCredentials({ sellerAccountId, authCode, location }) {
    return post(BASE_URL, {
      data: {
        amazon_seller_account_id: sellerAccountId,
        authorization_code: authCode,
        location
      }
    })
  }
}
