import { get, post, put, destroy, patch } from '../helpers'
import {
  buildFullPathClean,
  buildFullPath,
  buildFilter
} from '../../build_paths'

const SEGMENT_BUILDER_REQUEST_TIMEOUT = 120000

export default {
  getSegment(id, data = {}) {
    const path = buildFullPath(`api/segments/${id}`, data)
    return get(path)
  },
  getSegments(data = {}) {
    const path = buildFullPathClean('api/segments', data)
    return get(path)
  },
  getSegmentsSummary() {
    const path = 'api/segments/summary'
    return get(path)
  },
  createSegment(data) {
    const path = `api/segments`
    return post(path, { data })
  },

  changeSegmentActive(id, active) {
    const path = `api/segments/${id}`
    return patch(path, { data: { active } })
  },

  requestSegmentRefresh(id) {
    const path = `api/segments/${id}`
    return patch(path, { data: { processing: true } })
  },

  updateSegment(id, data) {
    const path = `api/segments/${id}`
    return put(path, { data })
  },

  deleteSegment(id) {
    const path = `api/segments/${id}`
    return destroy(path)
  },

  deleteBulkSegments(data) {
    const path = `api/segments/batch_delete`
    return destroy(path, { data: { segment_ids: data } })
  },

  updateBulkSegments(data, propsToUpdate) {
    const path = `api/segments/batch_update`
    return patch(path, { data: { segment_ids: data, ...propsToUpdate } })
  },

  getSegmentDetails(data) {
    const path = `api/segments/details`

    const filterParams = buildFilter(data.filters)

    const payload = {
      ...filterParams,
      skipCounter: false,
      excludeTopBrands: false
    }

    return post(path, {
      data: payload,
      timeout: SEGMENT_BUILDER_REQUEST_TIMEOUT
    })
  },

  getSegmentDetailsV2(payload) {
    const path = `api/segments/details`

    return post(path, {
      data: payload,
      timeout: SEGMENT_BUILDER_REQUEST_TIMEOUT
    })
  },

  request3pSellerExport(segmentId, payload) {
    const path = `api/segments/${segmentId}/excel_export_3p`

    return post(path, {
      data: payload
    })
  },

  requestExport(segmentId, payload) {
    const path = `api/segments/${segmentId}/excel_exports`

    return post(path, {
      data: payload
    })
  },

  getSegmentsLastProcessed(data = {}) {
    const path = buildFullPath('arkenstone/segments/lastprocessed', data)
    return get(path)
  },

  getSegmentCurrentMonthBalancePerMembership(membershipId) {
    const data = {
      withAsinPerSegment: true
    }
    const path = buildFullPath(
      `arkenstone/memberships/${membershipId}/balance`,
      data
    )
    return get(path)
  }
}
