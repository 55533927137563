import qs from 'qs'

import { get, createQueryStringUrl } from '../helpers'

const BASE_URL = 'api/junglekit/profit_overview'

const MAX_PROFIT_OVERVIEW_TIMEOUT = 60000 // 1 minute timeout

export default {
  getUnitsSold(
    amazon_seller_account_id,
    product_id,
    start_date,
    end_date,
    marketplace_id
  ) {
    const queryParams = qs.stringify(
      {
        amazon_seller_account_id,
        product_id,
        start_date,
        end_date,
        marketplace_id
      },
      { skipNulls: true }
    )
    const path = `${BASE_URL}/units_sold?${queryParams}`

    return get(path, { timeout: MAX_PROFIT_OVERVIEW_TIMEOUT })
  },

  getUnitsSoldOverTime(
    amazon_seller_account_id,
    product_id,
    start_date,
    end_date,
    marketplace_id,
    interval
  ) {
    const queryParams = qs.stringify(
      {
        amazon_seller_account_id,
        product_id,
        start_date,
        end_date,
        marketplace_id,
        interval
      },
      { skipNulls: true }
    )
    const path = `${BASE_URL}/units_sold_over_time?${queryParams}`

    return get(path, { timeout: MAX_PROFIT_OVERVIEW_TIMEOUT })
  },

  getProfitInfo(
    amazon_seller_account_id,
    product_id,
    start_date,
    end_date,
    marketplace_id
  ) {
    const queryParams = qs.stringify(
      {
        amazon_seller_account_id,
        product_id,
        start_date,
        end_date,
        marketplace_id
      },
      { skipNulls: true }
    )
    const path = `${BASE_URL}/profit_info?${queryParams}`

    return get(path, { timeout: MAX_PROFIT_OVERVIEW_TIMEOUT })
  },

  getProfitOverviewProducts(
    amazon_seller_account_id,
    marketplace_id,
    search,
    page,
    per_page,
    start_date,
    end_date,
    order_dir,
    order_by,
    comparison_start_date,
    comparison_end_date
  ) {
    const queryParams = qs.stringify(
      {
        amazon_seller_account_id,
        marketplace_id,
        search,
        page,
        per_page,
        start_date,
        end_date,
        comparison_start_date,
        comparison_end_date,
        order_dir,
        order_by
      },
      { skipNulls: true }
    )
    const path = `${BASE_URL}/products?${queryParams}`

    return get(path, { timeout: MAX_PROFIT_OVERVIEW_TIMEOUT })
  },

  getRevenueOverTime(
    amazon_seller_account_id,
    product_id,
    start_date,
    end_date,
    marketplace_id,
    interval
  ) {
    const queryParams = qs.stringify(
      {
        amazon_seller_account_id,
        product_id,
        start_date,
        end_date,
        marketplace_id,
        interval
      },
      { skipNulls: true }
    )
    const path = `${BASE_URL}/revenue_over_time?${queryParams}`

    return get(path, { timeout: MAX_PROFIT_OVERVIEW_TIMEOUT })
  },

  getRevenueSummary(
    amazon_seller_account_id,
    product_id,
    start_date,
    end_date,
    marketplace_id
  ) {
    const queryParams = qs.stringify(
      {
        amazon_seller_account_id,
        product_id,
        start_date,
        end_date,
        marketplace_id
      },
      { skipNulls: true }
    )
    const path = `${BASE_URL}/revenue?${queryParams}`

    return get(path, { timeout: MAX_PROFIT_OVERVIEW_TIMEOUT })
  },

  getNetProfitOverTime(
    amazon_seller_account_id,
    product_id,
    start_date,
    end_date,
    marketplace_id,
    interval
  ) {
    const params = {
      amazon_seller_account_id,
      product_id,
      start_date,
      end_date,
      marketplace_id,
      interval
    }
    // createQueryStringUrl removes blank parameters
    // (productId is blank when company level request)
    const path = createQueryStringUrl(
      `${BASE_URL}/net_profit_over_time`,
      params
    )

    return get(path, { timeout: MAX_PROFIT_OVERVIEW_TIMEOUT })
  },

  getNetProfitSummary(
    amazon_seller_account_id,
    product_id,
    start_date,
    end_date,
    marketplace_id
  ) {
    const params = {
      amazon_seller_account_id,
      product_id,
      start_date,
      end_date,
      marketplace_id
    }
    // createQueryStringUrl removes blank parameters
    // (productId is blank when company level request)
    const path = createQueryStringUrl(`${BASE_URL}/net_profit`, params)

    return get(path, { timeout: MAX_PROFIT_OVERVIEW_TIMEOUT })
  },

  getCostOverTime(
    amazon_seller_account_id,
    product_id,
    start_date,
    end_date,
    marketplace_id,
    interval
  ) {
    const params = {
      amazon_seller_account_id,
      product_id,
      start_date,
      end_date,
      marketplace_id,
      interval
    }
    // createQueryStringUrl removes blank parameters
    // (productId is blank when company level request)
    const path = createQueryStringUrl(`${BASE_URL}/cost_over_time`, params)

    return get(path, { timeout: MAX_PROFIT_OVERVIEW_TIMEOUT })
  },

  getCostSummary(
    amazon_seller_account_id,
    product_id,
    start_date,
    end_date,
    marketplace_id
  ) {
    const params = {
      amazon_seller_account_id,
      product_id,
      start_date,
      end_date,
      marketplace_id
    }
    // createQueryStringUrl removes blank parameters
    // (productId is blank when company level request)
    const path = createQueryStringUrl(`${BASE_URL}/cost`, params)

    return get(path, { timeout: MAX_PROFIT_OVERVIEW_TIMEOUT })
  },

  getProductPpc(amazon_seller_account_id, product_id, start_date, end_date) {
    const queryParams = qs.stringify(
      {
        amazon_seller_account_id,
        product_id,
        start_date,
        end_date
      },
      { skipNulls: true }
    )

    const path = `${BASE_URL}/ppc?${queryParams}`

    return get(path, { timeout: MAX_PROFIT_OVERVIEW_TIMEOUT })
  },

  getCompanyPpc(
    amazon_seller_account_id,
    marketplace_id,
    start_date,
    end_date
  ) {
    const queryParams = qs.stringify(
      {
        amazon_seller_account_id,
        marketplace_id,
        start_date,
        end_date
      },
      { skipNulls: true }
    )

    const path = `${BASE_URL}/ppc?${queryParams}`

    return get(path, { timeout: MAX_PROFIT_OVERVIEW_TIMEOUT })
  },

  getProductPpcOverTime(
    amazon_seller_account_id,
    productId,
    start_date,
    end_date
  ) {
    const queryParams = qs.stringify(
      {
        amazon_seller_account_id,
        start_date,
        end_date
      },
      { skipNulls: true }
    )

    const path = `${BASE_URL}/ppc_over_time/${productId}?${queryParams}`

    return get(path, { timeout: MAX_PROFIT_OVERVIEW_TIMEOUT })
  },

  getCompanyPpcOverTime(
    amazon_seller_account_id,
    marketplace_id,
    start_date,
    end_date
  ) {
    const queryParams = qs.stringify(
      {
        amazon_seller_account_id,
        marketplace_id,
        start_date,
        end_date
      },
      { skipNulls: true }
    )

    const path = `${BASE_URL}/ppc_over_time?${queryParams}`

    return get(path, { timeout: MAX_PROFIT_OVERVIEW_TIMEOUT })
  }
}
