import { SHARE_AND_TRENDS_REQUEST_TIMEOUT } from 'constants/market_insights/share_and_trends'
import { get } from '../helpers'
import { buildFullPath } from '../../build_paths'

export default {
  getSegmentTrends(id, data) {
    const path = buildFullPath(`api/segments/${id}/trends`, data)
    return get(path)
  },

  getBrandTrends(id, data) {
    const path = buildFullPath(`api/segments/${id}/brand_trends`, data)
    return get(path, { timeout: SHARE_AND_TRENDS_REQUEST_TIMEOUT })
  },

  getSegmentBrands(id, data) {
    const path = buildFullPath(`api/segments/${id}/brands`, data)
    return get(path, { timeout: SHARE_AND_TRENDS_REQUEST_TIMEOUT })
  },

  getSegmentBrandProducts(id, data, format = null) {
    const path = buildFullPath(
      `api/segments/${id}/brand_products`,
      data,
      format
    )
    return get(path, {
      responseType: format === 'csv' ? 'blob' : null,
      timeout: SHARE_AND_TRENDS_REQUEST_TIMEOUT
    })
  },

  getAsinCount() {
    return get(`api/segments/total_asins`, {
      timeout: SHARE_AND_TRENDS_REQUEST_TIMEOUT
    })
  }
}
